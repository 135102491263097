@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  animation: spinner 2s linear infinite;
}

/* --- wizard --- */

.mfr-wizard .react-form-wizard ul.form-wizard-steps {
  width: 40%;
  margin: 30px 0;
}
.mfr-wizard .react-form-wizard ul.form-wizard-steps li a {
  width: 95%;
}
.mfr-wizard .react-form-wizard ul.form-wizard-steps li a div.wizard-icon-circle {
  border-width: 4px;
  border-radius: 5px;
  height: 0;
  width: 100%;
}
.mfr-wizard .react-form-wizard ul.form-wizard-steps li a.active div.wizard-icon-circle.checked {
  border-color: #afb1e9;
}
.mfr-wizard .react-form-wizard ul.form-wizard-steps li a div.wizard-icon-circle.checked {
  border-color: #5261a0;
  cursor: pointer;
}
.mfr-wizard .react-form-wizard ul.form-wizard-steps li a div.wizard-icon-circle div {
  display: none;
  font-size: 0;
}
.mfr-wizard .react-form-wizard .wizard-tab-content {
  text-align: inherit;
}
.mfr-wizard .wizard-progress-with-circle {
  display: none;
}
.mfr-wizard .react-form-wizard .wizard-header {
  padding: 0;
}
.mfr-wizard .react-form-wizard .wizard-tab-content {
  padding: 0;
}
.mfr-wizard .react-form-wizard .wizard-card-footer {
  padding: 0;
}


table.summary-table {
  border-collapse: collapse;
  width: 100%;
}
table.summary-table tr th {
  background-color: #e7edff;
  line-height: 26px;
  text-align: left;
  padding: 7px 7px 7px 10px;
}
table.summary-table tr th svg {
  line-height: 26px;
}
table.summary-table tr th.text-right {
  text-align: right;
}
table.summary-table tr td {
  padding: 10px;
}
table.summary-table tr.sub-title td {
  background-color: transparent;
  font-weight: bold;
}
table.summary-table tr th:last-child,
table.summary-table tr td:last-child {
  text-align: right;
}
table.summary-table tr th:first-child:last-child,
table.summary-table tr td:first-child:last-child {
  text-align: left;
}
table.summary-table tr th:last-child {
  color: #5261a0;
}
table.summary-table tr:nth-child(even) td {
  background-color: #f7f9fc;
}

/* ------------------------------- */

.grid-mfr.MuiDataGrid-root {
  border-width: 0;
}
.grid-mfr.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  color: #757575;
}
.grid-mfr.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell {
  color: #7c7b84;
}
.grid-mfr.MuiDataGrid-root .MuiDataGrid-overlayWrapper {
  min-height: 75px;
}
.grid-mfr.MuiDataGrid-root .actions {
  text-align: right;
}
.grid-mfr.MuiDataGrid-root .MuiDataGrid-row .MuiDataGrid-cell .actions svg.MuiSvgIcon-root {
  color: #5261a0;
  cursor: pointer;
  margin-left: 10px;
}