@tailwind base;

.ag-theme-my-fillings {
    /*--ag-foreground-color: rgb(126, 46, 132);*/
    --ag-background-color: #FFFFFF;
    --ag-header-foreground-color: #757575;
    --ag-header-background-color: #FFFFFF; /*#51515F;*/
    /*--ag-odd-row-background-color: #E2E1F2;*/
    --ag-header-column-resize-handle-color: '#51515F';
    --ag-border-radius: 0px;
    --ag-row-height: 57px;
    --ag-normal-row-height: 71px;
    
    --ag-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    --ag-font-weight: 500;
    --ag-font-size: 0.875rem;
    --ag-borders: none;
    /* --ag-header-row-border-color: rgb(255, 183, 170); */
    /*--ag-font-family: monospace;*/
}

.ag-theme-payments {
    /*--ag-foreground-color: rgb(126, 46, 132);*/
    --ag-background-color: #FFFFFF;
    --ag-header-foreground-color: #FFFFFF;
    ;
    --ag-header-background-color: #5261A0; /*#51515F;*/
    /*--ag-odd-row-background-color: #E2E1F2;*/
    --ag-header-column-resize-handle-color: '#51515F';
    --ag-border-radius: 0px;
    --ag-row-height: 57px;
    --ag-normal-row-height: 71px;
    
    --ag-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    --ag-font-weight: 500;
    --ag-font-size: 0.875rem;
    --ag-borders: none;
    /* --ag-header-row-border-color: rgb(255, 183, 170); */
    /*--ag-font-family: monospace;*/
}
.ag-theme-my-fillings .ag-header-cell-text {
    font-weight: 500;
}
.ag-theme-my-fillings .ag-header-row{
    border-bottom: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);;
}
.ag-theme-my-fillings .ag-cell{
    color: #757575;
}
.grid-wrapper {
    flex: 1 1 0px;
    width: 100%;
}
